export const OtherRarityTools = ()=>{
    return (
        <>
            <div style={{margin: 8, marginTop: 64}}>
                <div>Our Partners - Monkex!</div>
                <OtherRarityTool name='Monkex.net' url='https://monkex.net/' />
                            </div>
        </>
    );
};

const OtherRarityTool = ({
    url,
    name,
}:{
    name: string,
    url: string,
})=>{
    return (
        <>
            <div>
                <a href={url} target='_blank' rel="noreferrer">{name}</a>
            </div>
        </>
    );
};

